<div class="container">

  <img loading="lazy" src="assets/appointments.jpg" width="100%" />

  <h1>Appointments</h1>

  <p>Appointments can be made online for any night with the exception of Fridays and Yom Tov.</p>
  <p>
    Appointment fee is $36.
    These fees are waived entirely for mikvah members. To learn more about membership, please see the <a
      routerLink="/fees-and-membership">membership page</a>.
  </p>
  <p>
    Appointments can be made up to one hour before the mikvah opens that day.
  </p>
  <p>
    Appointments can be cancelled anytime before the mikvah opens for a refund. After the mikvah opens, your appointment
    cannot be cancelled. <strong>If you will not be able to make your appointment or if you are running late, please call the mikvah directly at: <a href="tel:+13105504511">310-550-4511</a> and we will work to accommodate you.</strong>
  </p>
  <p>
    Please show up on time. If you are not at the mikvah at the time of the appointment, you will lose your appointment,
    and any fees charged will not be refunded.
  </p>
  <p>If you have COVID, please call to make arrangements. Call <a href="tel:+13109484432">310-948-4432</a> (for Vivian).</p>
  <p>
    <strong>All shower appointments are scheduled at 30 minute intervals, bath appointments are scheduled at one hour intervals. If you feel you will need more time, please just advise an attendant when you arrive.</strong>
  </p>

  <p *ngIf="!isLoggedIn">
    You must <a (click)="logIn()" class="link" href="javascript:void(0);">log in</a> to make an appointment.
  </p>

  <div [ngClass]="alertClasses" role="alert" *ngIf="showMessage">
    {{message}}
  </div>

  <div class="card" *ngIf="isLoggedIn && hasCurrentAppointment">
    <div class="card-body">
      <h4 class="card-title">Current Appointment</h4>
      <p class="card-text">{{ currentAppointment }}</p>
      <input class="btn btn-danger" *ngIf="isCancellable" (click)="cancelAppointment()" type="button" value="Cancel">
    </div>
  </div>

  <div class="card" *ngIf="hasAvailabilityData && isLoggedIn && !hasCurrentAppointment">
    <div class="card-body">
      <h4 class="card-title">Create Appointment</h4>
      <form (ngSubmit)="onSubmit(createAppointmentForm)" #createAppointmentForm="ngForm">
        <div class="form-group">
          <label for="date">Date</label>
          <select class="form-control" id="date" name="date" [(ngModel)]="model.date" #dateField="ngModel"
            (change)="onDateSelectionChange()" required>
            <option *ngFor="let day of availableDays" [value]="day.isoDay">{{day.displayDay}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="date">Room Type</label>
          <select class="form-control" id="roomType" name="roomType" [(ngModel)]="model.roomType" #dateField="ngModel"
            (change)="onRoomTypeSelectionChange()" required>
            <option *ngFor="let roomType of availableRoomTypes" [value]="roomType">{{roomType | titlecase}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="time">Time</label>
          <select class="form-control" id="time" name="time" [(ngModel)]="model.time" #timeField="ngModel" required>
            <option *ngFor="let time of availableTimes; let i = index" [value]="time.isoTime">{{time.displayTime}}
            </option>
          </select>
        </div>

        <div class="form-group" *ngIf="!isMember">
          <!-- Used to display form errors -->
          <label for="card-element">Payment Method</label>
          <div class="form-check" *ngIf="hasSavedCreditCard">
            <input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod1"
              value="saved" [(ngModel)]="model.paymentMethod" checked>
            <label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
              {{ savedCreditCard }}
            </label>
          </div>
          <div class="form-check" *ngIf="hasSavedCreditCard">
            <input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod2"
              value="new" [(ngModel)]="model.paymentMethod">
            <label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
              Use a different card
            </label>
          </div>
          <div id="card-element" class="form-control" #cardInfo>
            <!-- a Stripe Element will be inserted here. -->
          </div>
          <div id="card-errors" class="card-errors" role="alert" *ngIf="error">{{ error }}</div>
        </div>
        <div class="form-group">
          <label for="notes" translate>Notes</label>
          <textarea [(ngModel)]="model.notes" #notes="ngModel" type="text" class="form-control" id="notes"
            name="notes"></textarea>
          <small id="notesHelp" class="form-text text-muted">These notes will be shared with the mikvah
            attendant.</small>
        </div>
        <button type="submit" class="btn btn-primary"
          [disabled]="appointmentCreationFormSubmissionInProgress">Reserve</button>
      </form>
    </div>
  </div>

</div>
