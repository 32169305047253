<div class="container">

<img loading="lazy" src="assets/donations.jpg" width="100%"/>

<h2>Donation Opportunities</h2>

<div>
	The Mikvah of Los Angeles is an integral part of the Los Angeles community and as such needs community
	support to thrive. Apart from annual membership, please consider the following ways in which you
	can play a vital role in our Mikvah:
</div>
<ul>
	<li>
		<strong>SPONSOR A BRIDE- $500.00 (and up)</strong><br />
		Perhaps you will attend a wedding of a couple who has everything they need, or a couple
		whose budget will be stretched by Mikvah fees for the year. Now, you can sponsor a bride for
		a year&rsquo;s Mikvah attendance and have a share in their mitzvah.
	</li>
	<li>
		<strong>FRIEND OF THE MIKVAH- $180.00</strong><br />
		If you would like to support the Mikvah, but are not in a position to become an
		annual member, this donation opportunity is available. For $180.00 you can
		become a Friend of the Mikvah for the year.
	</li>
	<li>
		<strong>YAHRZEIT AND MEMORIAL CONTRIBUTIONS- $54.00 (and up)</strong><br />
		Each member of the Jewish community is a link in the chain of our history.
		Consider a yahrzeit or memorial contribution connected to a mitzvah which
		strengthens this same chain.
	</li>
</ul>
<div>
	For donations in excess of $500.00, which would entitle you to an annual Mikvah membership and its benefits,
	please click on the <a routerLink="fees-and-membership">Fees/Membership</a> tab.
</div>
<div class="card donation-card">
		<div class="card-body">
		  <h4 class="card-title">Make A Donation</h4>
		  <div [ngClass]="alertClasses" role="alert" *ngIf="showMessage" >
				{{message}}
		 </div>
		  <form (ngSubmit)="onSubmit(donationForm)" #donationForm="ngForm">

		<div class="form-group" *ngIf="!isLoggedIn">
				<label for="name">Name</label>
				<input class="form-control" id="name" name="name" [(ngModel)]="model.name" #nameField="ngModel" type="text" required />
		</div>

		<div class="form-group" *ngIf="!isLoggedIn">
				<label for="name">E-mail</label>
				<input class="form-control" id="email" name="email" [(ngModel)]="model.email" #emailField="ngModel" type="email" required />
		</div>
			
			<div class="form-group">
			  <label for="amount">Amount</label>
			  <input class="form-control" id="amount" name="amount" [(ngModel)]="model.amount" #amountField="ngModel" type="number" step="0.01" min="1" list="defaultNumbers" required />
			  <datalist id="defaultNumbers">
					<option value="500.00">Sponsor A Bride</option>
					<option value="180.00">Friend Of The Mikvah</option>
					<option value="54.00">Yartzeit and Memorial Contribution</option>
				  </datalist>
			</div>
	
			  <div class="form-group">
				<!-- Used to display form errors -->
				<label for="card-element">Payment Method</label>
				<div class="form-check" *ngIf="hasSavedCreditCard">
					<input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod1" value="saved" [(ngModel)]="model.paymentMethod" checked>
					<label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
					  {{ savedCreditCard }}
					</label>
				</div>
				<div class="form-check" *ngIf="hasSavedCreditCard">
					<input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod2" value="new" [(ngModel)]="model.paymentMethod">
					<label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
					  Use a different card
					</label>
				</div>
				<div id="card-element" class="form-control" #cardInfo>
					<!-- a Stripe Element will be inserted here. -->
				</div>
				<div id="card-errors" class="card-errors" role="alert" *ngIf="error">{{ error }}</div>
			  </div>
			  <button type="submit" class="btn btn-primary" [disabled]="donationInProgress">Donate</button>
		  </form>
		</div>
	</div>