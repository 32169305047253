<div class="container">

<img loading="lazy" src="assets/mens_mikvah.jpg" width="100%"/>

<h1>Men's Mikvah</h1>

<div>
	The Men's Mikvah is located at:
</div>
<div class="address">
	<em>9548 W. Pico Boulevard<br />
	Los Angeles, CA 90035</em>
</div>
<div>
	Parking and entrance are located in the back.
</div>
<h2>
	More information is available at <a href="https://mikveh.men" rel="noopener" target="_blank">https://mikveh.men</a>.
</h2>
<!-- <h3 class="pt-2">Yom Tov Schedule</h3>
<table class="table table-bordered">
	<thead>
		<tr>
			<th>Day</th>
			<th>Hours</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>Erev Rosh Hashana, Sunday, September 9</td>
			<td>
				<div>
					<div>4:45 a.m. — 11:00 a.m.</div>
					<div>The Mikveh will be closed for maintenance from 11:00 a.m. until 1:00 p.m.</div>
					<div>1:00 p.m. — 6:00 p.m.</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>
				<div>Rosh Hashana, Monday & Tuesday</div>
				<div>September 10 & 11</div>
			</td>
			<td>
				<div>
					<div>6:00 a.m. — 9:45 a.m.</div>
				</div>
			</td>
		</tr>
		<tr>
			<td>Erev Yom Kippur, Tuesday, September 18</td>
			<td>
				<div>
					<div>4:30 a.m. — 11:00 a.m.</div>
					<div>The Mikveh will be closed for maintenance from 11:00 a.m. until 1:00 p.m.</div>
					<div>1:00 p.m. — 6:00 p.m.</div>
				</div>
			</td>
		</tr>
		<tr>
				<td>Yom Kippur, Wednesday, September 19</td>
				<td>
					<div>
						<div>Closed</div>
					</div>
				</td>
			</tr>
	</tbody>
</table>

<h3>Regular Schedule</h3> -->
<!-- <div class="address">
	Monday-Thursday<br />
	5:30 AM - 8:15 AM<br />
	<br />
	Friday<br />
	5:30 AM-8:15 AM<br />
	1:30 PM - 40 minutes before candle lighting<br />
	<br />
	Every Sunday<br />
	6:30 AM - 9:15 AM
	<p><b>Fee: $5</b></p>
</div> -->
<div class="pb-4">
	For questions about the Men's Mikvah please email <a href="mailto:tahara@mikveh.men">tahara@mikveh.men</a>
</div>