export class AppointmentTime {

  constructor(
    public date: string,
    public time: string,
    public roomType: string,
    public paymentMethod: string,
    public notes: string
  ) {  }

}
