export class DisplayHours {

    constructor(
      public start: string,
      public end: string,
      public weekday: string,
      public closed: boolean
    ) {  }
  
  }
  