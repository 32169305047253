export class Donation {

  constructor(
    public name: string,
    public email: string,
    public amount: number,
    public paymentMethod: string
  ) {  }

}
