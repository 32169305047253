<header>
  <nav class="navbar navbar-expand-xl navbar-dark bg-dark">
    <a class="navbar-brand" href="/" translate>Los Angeles Mikvah Society</a>
    <button class="navbar-toggler" type="button" aria-controls="navbar-menu"
            aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav mr-auto">
        <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <i class="fa fa-home"></i>
          <span translate>Home</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/appointments" routerLinkActive="active">
          <i class="fa fa-calendar"></i>
          <span translate>Appointments</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/fees-and-membership" routerLinkActive="active">
          <i class="fa fa-credit-card"></i>
          <span translate>Fees/Membership</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/faqs" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>FAQs</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/directions" routerLinkActive="active">
          <i class="fa fa-location-arrow"></i>
          <span translate>Directions</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/donations" routerLinkActive="active">
          <i class="fa fa-money"></i>
          <span translate>Donations</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/mens-mikvah" routerLinkActive="active">
          <i class="fa fa-male"></i>
          <span translate>Men's Mikvah</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/admin" routerLinkActive="active" *ngIf="isAdmin">
          <i class="fa fa-clipboard"></i>
          <span translate>Admin</span>
        </a>


      </div>
      <div class="navbar-nav">
        <a class="nav-item nav-link text-uppercase ml-auto pointer" (click)="login()" *ngIf="!loggedIn">
            <i class="fa fa-user-circle"></i>
            <span translate>Login/Register</span>
        </a>
        <div class="nav-item" ngbDropdown *ngIf="loggedIn" placement="bottom-right">
          <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
            <i class="fa fa-user-circle"></i> <span>{{userName}}</span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/profile" routerLinkActive="active">Profile</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item pointer" (click)="logout()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
