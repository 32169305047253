<div mat-dialog-content>
  <form *ngIf="action != 'Delete'; else elseTemplate">
    <div class="form-group">
          <label for="time" translate>Time</label>
          <select class="form-control" id="time" name="time" [(ngModel)]="local_data.time" #timeField="ngModel">
            <option *ngFor="let time of local_data.times; let i = index" [value]="time.isoTime">{{time.displayTime}}
            </option>
          </select>
      </div>
      <div class="form-group">
        <label for="notes" translate>Notes</label>
        <textarea [(ngModel)]="local_data.obj.notes" #notes="ngModel" type="text" class="form-control" id="notes"
          name="notes"></textarea>
      </div>
    </form>
  <ng-template #elseTemplate>
    Delete <b>{{local_data.obj.time}}</b> appointment for <b>{{local_data.obj.email}}</b>? 
    <br><small>Deleting the appointment will cause any payments to be refunded.</small>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{action}}</button>
  <button mat-button (click)="closeDialog()">Cancel</button>
</div>
