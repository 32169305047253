<div class="container">

    <div class="row">
        <img loading="lazy" src="assets/home.jpg" class="intro-image" width="100%" alt="Mikvah Home"/>
    </div>
    

    <div class="row">
        <div class="col-sm-8">
            <h2>Welcome to The Mikvah Society of Los Angeles</h2>

            <p>
            The <em>mitzvah</em> of <em>Taharat Hamishpacha</em> is central to the Jewish people
            and its observance brings holiness and purity into our family lives.
            </p>

            <p>
                Please understand that we are an appointment-only based Mikvah. We do not allow walk-ins, except on Friday nights
                and Yom Tov evenings. Payment for these appointments can be made in advance via Venmo or Zelle to: 310-948-4432.
            </p>


            <p>
            With the aim of building a community Mikvah, The Mikvah Society of Los Angeles was
            founded in January 1974. It is a non-profit organization and relies on
            community support for its operation and maintenance.
            </p>
            <p>
            Since it opened its doors in October 1976, <em>Mikvat Esther</em>, The Mikvah
            Society of Los Angeles, has grown along	with the greater LA community.
            </p>
            <p>
            The Mikvah is open every night during the year, except on <em>Yom Kippur</em>
            and <em>Tisha B&rsquo;av</em>.
            </p>
            <p>
            All Halachic issues and policies are determined by the <em>Vaad Ha-Rabbanim</em>:
            </p>
            <ul>
            <li>
            Rabbi Aryeh Adler
            </li>
            <li>
            Rabbi Mordechai Lebhar
            </li>
            <li>
            Rabbi Yitzchok Summers
            </li>
            <li>
            Rabbi Sholom Tendler
            </li>
            <li>
            Rabbi Yosef Shusterman
            </li>
            </ul>
            <p>Please feel free to reach out by text, call or email, if you have any questions or require a special appointment to: 310-948-
                4432 or info@lamikvah.org</p>
                
            <p>Thank you,</p>
            <p>The Board of the Los Angeles Mikvah</p>
            <div id="spacer"></div>
            <div id="auth0badge">
                <a width="150" height="50" rel="noopener" href="https://auth0.com/?utm_source=oss&utm_medium=gp&utm_campaign=oss" target="_blank" alt="Single Sign On & Token Based Authentication - Auth0"><img width="150" height="50" alt="JWT Auth for open source projects" src="//cdn.auth0.com/oss/badges/a0-badge-light.png"/></a>
            </div>
        </div>
        <div class="col-sm-4 schedule">
            <h3>This Week's Schedule</h3>
            <p>
                <strong>
                    Week of {{ firstDayInWeek }}<br/>
                    <br/>
                    <span *ngFor="let day of dailyHours">
                        <br *ngIf="day.weekday === 'Fri'"/>
                        <span *ngIf="!day.closed">{{ day.start }} - {{ day.end }} ({{ day.weekday }})</span>
                        <span *ngIf="day.closed">CLOSED ({{ day.weekday }})</span>
                        <br/>    
                    </span>
                </strong>
            </p>
            <br/>
            <br />
            <strong>Please make sure to arrive at least one half hour before closing time, so you can be finished by closing time
                and avoid late fees.</strong>
            <small>
            <br/>
            <br />
            Please note that on Motzaei Shabbat as well as Motzaei Yom Tov (Saturday Nights and at the close of Holidays), no one will be allowed into the Mikvah until 45 minutes after Shabbat/Chag ends when the Mikvah attendant arrives.
            </small>
        </div>
      </div>
    
</div>
