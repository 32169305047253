<div class="container">

<img loading="lazy" src="assets/fees_membership.jpg" width="100%"/>

<h1>Fees</h1>
<p>
The fee for each Mikvah visit is $36.00. Appointments should be made online.
</p>
<p>
A late fee will be charged if you arrive at the mikvah less than 25 minutes before closing.
</p>
<p>
Annual membership payments can be made online via credit or debit card.
See below for information on joining.
</p>

<h1>Membership</h1>
<p>Membership is an important way to help support the mikvah.</p>
<p>
There is no individual use fee for Mikvah members.
</p>

<p>There are three different levels of membership available to show your support:</p>
<ul>
<li>
<p>
  $500 Silver Membership
</p>
</li>
<li>
<p>
  $1,000 Gold Membership
</p>
</li>
<li>
<p>
  $1,800 Chai Membership
</p>
</li>
</ul>

<p *ngIf="!isLoggedIn">
    You must <a (click)="logIn()" class="link" href="javascript:void(0);" >log in or create an account</a> to become a member.
  </p>
  
  <div [ngClass]="alertClasses" role="alert" *ngIf="showMessage" >
    {{message}}
  </div>
  
  <div class="card membership-card" *ngIf="isLoggedIn && isMember">
      <div class="card-body">
        <h4 class="card-title">Membership</h4>
        <p class="card-text">You are currently a member at the {{ currentLevel }} level.</p>
        <p class="card-text">Your membership will expire on {{ expirationDate }}.</p>
        <p class="card-text" *ngIf="autoRenewalEnabled && hasSavedCreditCard">Your membership will automatically renew at that time, to disable automatic renewal, please click "Do Not Automatically Renew My Membership".</p>
        <p class="card-text" *ngIf="!autoRenewalEnabled && hasSavedCreditCard">Your membership will NOT automatically renew at that time, to enable automatic renewal, please click "Renew My Membership Automatically".</p>
        <p class="card-text" *ngIf="autoRenewalEnabled && hasSavedCreditCard">Renewals will be charged on your {{ savedCreditCard }}.</p>
        <p class="card-text" *ngIf="autoRenewalEnabled && hasSavedCreditCard">To switch to a different card, please change it on <a routerLink="/profile">the profile page.</a></p>
        <input class="btn btn-danger" *ngIf="autoRenewalEnabled && hasSavedCreditCard" (click)="disableAutoRenew()" type="button" value="Do Not Automatically Renew My Membership">
        <input class="btn btn-primary" *ngIf="!autoRenewalEnabled && hasSavedCreditCard" (click)="enableAutoRenew()" type="button" value="Renew My Membership Automatically">
      </div>
  </div>
  
  <div class="card membership-card" *ngIf="isLoggedIn && !isMember">
      <div class="card-body">
        <h4 class="card-title">Membership</h4>
        <form (ngSubmit)="onCreateSubmit(createAppointmentForm)" #createAppointmentForm="ngForm">
          <div class="form-group">
            <label for="level">Level</label>
            <select class="form-control" id="level" name="level" [(ngModel)]="model.plan" #levelField="ngModel" required>
              <option value="silver-1">Silver - $500</option>
              <option value="gold-1">Gold - $1,000</option>
              <option value="platinum-1">Chai - $1,800</option>
            </select>
          </div>
  
            <div class="form-group" *ngIf="!isMember">
              <!-- Used to display form errors -->
              <label for="card-element">Payment Method</label>
              <div class="form-check" *ngIf="hasSavedCreditCard">
                  <input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod1" value="saved" [(ngModel)]="model.paymentMethod" checked>
                  <label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
                    {{ savedCreditCard }}
                  </label>
              </div>
              <div class="form-check" *ngIf="hasSavedCreditCard">
                  <input class="form-check-input payment-radio" type="radio" name="paymentMethod" id="paymentMethod2" value="new" [(ngModel)]="model.paymentMethod">
                  <label class="form-check-label mikvah-checkbox-label" for="paymentMethod">
                    Use a different card
                  </label>
              </div>
              <div id="card-element" class="form-control" #cardInfo>
                  <!-- a Stripe Element will be inserted here. -->
              </div>
              <div id="card-errors" class="card-errors" role="alert" *ngIf="cardError">{{ cardError }}</div>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="membershipCreationInProgress">Join</button>
        </form>
      </div>
  </div>


</div>
