<div class="container">

<img loading="lazy" src="assets/directions.jpg" width="100%"/>

<h1>Directions</h1>
<div>
	The Los Angeles Mikvah is located at:
</div>

<div class="address"><a href="https://maps.google.com/maps?q=9548+W.+Pico+Blvd,+Los+Angeles">
	<em>9548 W Pico Blvd<br />
	Los Angeles, CA 90035</em></a>
</div>
<div>
	West of Beverly Drive between Reeves Street and Edris Drive.
</div>
<div>
	The main entrance is located behind the building where free parking is available. A
	security guard is on duty at the rear entrance.
</div>
<iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=9548+W.+Pico+Blvd,+Los+Angeles&amp;ie=UTF8&amp;hq=&amp;hnear=9548+W+Pico+Blvd,+Los+Angeles,+California+90035&amp;t=m&amp;vpsrc=0&amp;z=14&amp;iwloc=A&amp;output=embed"></iframe>

</div>