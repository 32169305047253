// `.env.json` is generated by the `npm run build` command
import * as envData from './.env';

export const environment = {
  production: true,
  version: envData.env.npm_package_version,
  serverUrl: 'https://mikvah.site',
  callbackUrl: 'https://lamikvah.org/callback',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  stripeKey: 'pk_live_dLo2lxvl9eKLaOqwk4XKFOi3'
};
